import { useQuery } from "@apollo/react-hooks";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import capitalize from "lodash/capitalize";
import React from "react";
import Button from "../components/Button";
import Box from "../components/elements/Box";
import Text from "../components/elements/Text";
import Pagination from "../components/Pagination";
import PlaceholderBox from "../components/PlaceholderBox";
import PropertyCard from "../components/PropertyCard";
import Select from "../components/Select";

const PropertiesList = ({ location }) => {
  const {
    page = 1,
    area = undefined,
    category = undefined,
    price = undefined
  }: {
    page: number;
    area: undefined | string;
    category: undefined | string;
    price: undefined | string;
  } = location.state || {};

  const { loading, data, error } = useQuery(
    gql`
      query properties(
        $skip: Int
        $take: Int
        $area: String
        $price: String
        $category: String
      ) {
        prices(area: $area, category: $category) {
          name
          count
        }
        areas(category: $category, price: $price) {
          name
          count
        }
        categories(area: $area, price: $price) {
          name
          count
        }
        properties(
          skip: $skip
          take: $take
          area: $area
          price: $price
          category: $category
        ) {
          items {
            pid
            title
            imageUrls
            price
            size
          }
          total
          hasNextPage
        }
      }
    `,
    {
      variables: {
        skip: page === 1 ? 0 : (page - 1) * 30,
        take: 30,
        area: area ? area : undefined,
        category: category ? category : undefined,
        price: price ? price : undefined
      }
    }
  );

  if (error)
    return (
      <Box minHeight="50vh">
        <Text
          color="red"
          textAlign="center"
          fontSize={3}
          fontWeight="bold"
          content="連接伺務器失敗"
        ></Text>
      </Box>
    );

  const selectedCategory =
    loading || !data.categories
      ? undefined
      : data.categories.find(c => c.name === category);

  const selectedArea =
    loading || !data.areas ? undefined : data.areas.find(a => a.name === area);

  const selectedPrice =
    loading || !data.prices
      ? undefined
      : data.prices.find(p => p.name === price);

  return (
    <>
      <Box
        width={[1]}
        // mb={[4, 4, 6]}
        flexBox
        justifyContent={["space-between"]}
        flexWrap={"wrap"}
        flexDirection={["column-reverse", "column-reverse", "row"]}
      >
        <Text
          px={[1]}
          pt={[2]}
          fontSize={["0.7rem", 2]}
          content="(房價以歐元顯示。1歐元大約為8.9港元)"
          width={[1, 1, 1 / 3]}
          textAlign={["center", "left"]}
        ></Text>
        <Box flexBox flexWrap={["wrap", "nowrap"]} width={[1, 1, 2 / 3]}>
          <Select
            px={[1]}
            py={["0.1rem", 0]}
            width={[1, 1, 1 / 2]}
            selectProps={{
              isSearchable: false,
              isClearable: true,
              isLoading: loading,
              placeholder: "類型",
              value: selectedCategory
                ? {
                    label: `${selectedCategory.name} (${selectedCategory.count})`,
                    value: selectedCategory
                  }
                : null,
              options: loading
                ? []
                : data.categories
                    .filter(category => category.count > 0)
                    .map(category => ({
                      label: `${category.name} (${category.count})`,
                      value: category
                    })),
              onChange: (selected, { action }) => {
                if (action === "clear") {
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      // area: {
                      //   label: data.areas.find(a => a.name === area.name).name,
                      //   value: data.areas.find(a => a.name === area.name).value
                      // },
                      category: undefined
                    }
                  });
                  // setCategory(undefined);
                } else if (action === "select-option" && selected) {
                  // setCategory(selected.value);
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      category: selected.value.name
                    }
                  });
                }
              }
            }}
          ></Select>
          <Select
            px={[1]}
            py={["0.1rem", 0]}
            width={[1, 1, 1 / 2]}
            selectProps={{
              isSearchable: false,
              isClearable: true,
              isLoading: loading,
              placeholder: "地區",
              value: selectedArea
                ? {
                    label: `${capitalize(selectedArea.name)} (${
                      selectedArea.count
                    })`,
                    value: selectedArea.name
                  }
                : undefined,
              options: loading
                ? []
                : data.areas
                    .filter(area => area.count > 0)
                    .map(area => ({
                      label: `${capitalize(area.name)} (${area.count})`,
                      value: area
                    })),
              onChange: (selected, { action }) => {
                if (action === "clear") {
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      area: undefined
                    }
                  });
                } else if (action === "select-option" && selected) {
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      area: selected.value.name
                    }
                  });
                }
              }
            }}
          ></Select>

          <Select
            px={[1]}
            py={["0.1rem", 0]}
            width={[1, 1, 1 / 2]}
            selectProps={{
              isSearchable: false,
              isClearable: true,
              isLoading: loading,
              placeholder: "價格",
              value: selectedPrice
                ? {
                    label: `${selectedPrice.name} (${selectedPrice.count})`,
                    value: selectedPrice.name
                  }
                : null,
              options: loading
                ? []
                : data.prices
                    .filter(price => price.count > 0)
                    .map(price => ({
                      label: `${price.name} (${price.count})`,
                      value: price
                    })),
              onChange: (selected, { action }) => {
                if (action === "clear") {
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      price: undefined
                    }
                  });
                } else if (action === "select-option" && selected) {
                  navigate("/properties/", {
                    state: {
                      ...location.state,
                      page: 1,
                      price: selected.value.name
                    }
                  });
                }
              }
            }}
          ></Select>
        </Box>
      </Box>

      <Pagination
        totalCount={loading ? 0 : data.properties.total}
        currentPage={page}
        showPages
        hasBack={!loading && page > 1}
        back={{
          to: "/properties/",
          state: { ...location.state, page: page - 1 }
        }}
        // back={<Link to="/properties/" state={{ page: page - 1 }} />}
        hasNext={loading ? false : data.properties.hasNextPage}
        next={{
          to: "/properties/",
          state: { ...location.state, page: page + 1 }
        }}
        location={location}
      />

      <Box flexBox flexWrap="wrap" width={1} minHeight={["50vh"]}>
        {loading ? (
          Array.from({ length: 12 }).map((_, i) => (
            <Box
              key={`property-placeholder-${i}`}
              pr={[
                0,
                (i + 1) % 2 === 0 ? "0.25rem" : 0,
                (i + 1) % 3 === 0 ? "0.25rem" : 0
              ]}
              pl={[0, "0.25rem"]}
              py={["calc(0.25rem / 2)"]}
              width={[1, 1 / 2, 1 / 3]}
              // minHeight={250}
            >
              <PlaceholderBox height={260} />
            </Box>
          ))
        ) : data.properties.items.length === 0 ? (
          <Box
            width={1}
            flexBox
            justifyContent={["flex-start"]}
            alignItems="center"
            flexDirection="column"
          >
            <Text
              color="red"
              textAlign="center"
              fontSize={3}
              fontWeight="bold"
              content="沒有相關樓盤"
            ></Text>
            <Button
              mt={[4, 5]}
              mx="auto"
              onClick={() => navigate("/properties/", { state: {} })}
            >
              回目錄
            </Button>
          </Box>
        ) : (
          data.properties.items.map((property, i) => (
            <Box
              key={`property-item-${i}`}
              pr={[
                0,
                (i + 1) % 2 === 0 ? "0.25rem" : 0,
                (i + 1) % 3 === 0 ? "0.25rem" : 0
              ]}
              pl={[0, "0.25rem"]}
              py={["calc(0.25rem / 2)"]}
              width={[1, 1 / 2, 1 / 3]}
              // minHeight={250}
            >
              <PropertyCard {...{ ...property, location }} />
            </Box>
          ))
        )}
      </Box>

      {!loading && data.properties.items.length > 1 && (
        <Pagination
          totalCount={loading ? 0 : data.properties.total}
          currentPage={page}
          showPages
          hasBack={page > 1}
          back={{
            to: "/properties/",
            state: { ...location.state, page: page - 1 }
          }}
          // back={<Link to="/properties/" state={{ page: page - 1 }} />}
          hasNext={loading ? false : data.properties.hasNextPage}
          next={{
            to: "/properties/",
            state: { ...location.state, page: page + 1 }
          }}
          location={location}
        ></Pagination>
      )}
    </>
  );
};

export default PropertiesList;
