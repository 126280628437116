import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import Heading from "../../components/elements/Heading";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Section from "../../components/Section";
import SEO from "../../components/Seo";
import PropertiesList from "../../container/PropertiesList";

const PropertiesPage = ({ location }) => {
  const { bg } = useStaticQuery(graphql`
    {
      bg: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="精選樓盤" />

      <PageHeader
        backgroundPosition="0 50%"
        backgroundImage={bg.childImageSharp.fluid}
      >
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]} content="精選樓盤" />
        </Fade>
      </PageHeader>

      <Section
        css={`
          a {
            text-decoration: none;
            font-size: 20px;
            color: ${props => props.theme.colors.primary};
          }

          .property__item {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 250px;
            transition: all 0.3s ease;

            .icon__wrapper {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .content__wrapper {
              opacity: 1;
              position: absolute;
              bottom: 0;
              left: 0;
              top: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              background-color: rgba(0, 0, 0, 0.9);
              background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
              transition: all 0.3s ease;
              a {
                transition: all 0.3s ease;
              }
              p {
                transition: all 0.35s ease;
              }
            }
          }
        `}
      >
        <PropertiesList location={location} />
      </Section>
    </Layout>
  );
};

export default PropertiesPage;

// const StyleWrapper = styled.section`

// `;
