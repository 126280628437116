import { Link } from "gatsby";
import React from "react";
import { serverUrl } from "../lib/config";
import { formatPrice } from "../lib/formatPrice";
import Box from "./elements/Box";
import Image from "./elements/Image";
import Text from "./elements/Text";
import FeatureBlock from "./FeatureBlock";

const PropertyCard = ({ pid, imageUrls, title, size, price, location }) => (
  <Link to={`/properties/p?pid=${pid}`} state={location && location.state}>
    <FeatureBlock
      className="property__item"
      icon={
        <Image
          // src={`${serverUrl}${
          //   imageUrls[Math.floor(Math.random() * imageUrls.length)]
          // }`}
          src={`${serverUrl}${imageUrls[0]}`}
          alt={`Property Image ${pid}`}
          className="property__image"
        ></Image>
      }
      title={
        <Text
          content={title}
          fontSize={[3]}
          fontWeight={400}
          color="white"
          letterSpacing="-0.0001rem"
          pl={2}
          mb={1}
        />
      }
      description={
        <Box flexBox justifyContent="space-between" px={2} mb={2}>
          <Text
            // as="p"
            content={size}
            fontSize={[3]}
            lineHeight="1"
            color="rgba(255, 255, 255, 0.8)"
          />
          <Text
            // as="p"
            content={formatPrice(price)}
            fontSize={[3]}
            lineHeight="1"
            color="rgba(255, 255, 255, 0.8)"
          />
        </Box>
      }
    ></FeatureBlock>
  </Link>
);

export default PropertyCard;
